<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row justify-content-center" >
      <div class="col-8 col-md-6 text-center">
          <img src="@/assets/images/app/support_landing.png" class="img-fluid animate__animated animate__fadeInUp">
      </div>
      <div class="col-12 text-center animate__animated animate__fadeInUp">
        <h1 class="font-size-22">{{$t('siteLang.CustomerService')}}</h1>
        <p class="mb-1">{{$t('siteLang.BusinessHours')}}:</p>
        <p>{{$t('siteLang.MondaySunday')}}  |  9am - 9pm</p>
        <a href="http://api.whatsapp.com/send/?phone=60" class="btn btn-primary mb-4" target="_blank">{{$t('siteLang.ContactUs')}}</a>
      
      </div>
    </div>
    <Lottie :title="'Loading'" :info="'Please wait...'" :show="showLottie" ref="lottieFunc" />
    <Common ref="commonFunc"/>
  </Layout>
</template>
<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
/**
 * Starter page
 */
export default {
  components: { 
    Layout, 
    PageHeader, 
    Lottie,
    Common
  },
  page() {
    return {
      title: this.$t('siteLang.Support'),
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  data() {
    return {
      showLottie:false,
      title: '',
      items: [
        {
          text: appConfig.title,
          to: "/",
        },
        {
          text:'' ,
          active: true,
        },
      ],
      text:""
    };
  },
  mounted(){
    this.title = this.$t('siteLang.Support')
    this.items[1].text = this.$t('siteLang.Support') 
  },
  middleware: "authentication",
  
};
</script>